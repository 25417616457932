// Import Open Sans font
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap');

// Variables
$primary: rgb(70, 236, 153);
$primary: rgb(80, 162, 255);
$background: rgb(226, 226, 212);
$background: rgb(116, 116, 116);


$black: #000;
$light-black: rgb(45, 45, 45);
$light-gray: rgb(173, 173, 173);


$white: #fff;
$light-white: rgb(224, 224, 224);

// Mixins
@mixin flexCenter() {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

* {
  font-family: 'Oswald', sans-serif;
}

html,
body {
  margin: 0;
  height: 100%;
}

body {
  background-color: $white;
}