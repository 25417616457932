@import '../styles';

html,
body,
#root,
.App {
  margin: 0;
  padding: 0;
  height: 100%;

  overflow-x: hidden;
}


.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

h1 {
  color: black;
  text-align: center;
  background-color: rgb(255, 255, 255);
  margin-top: 0px;
  padding: 10px;
}

p {
  text-align: center;

  position: absolute;
  bottom: 0;
}

.content {
  z-index: 0;
  height: 100%;
}