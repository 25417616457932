@import '../styles';

.main-footer {
    color: $white;
    background-color: $primary;

    box-shadow: 0px 2px 5px #c0c0c0;


    bottom: 0;


    width: 100%;
    height: 20vh;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}


.list-unstyled {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#logo {
    background-image: url('../images/logo.jpg');
    height: 5em;
    width: 5em;
    background-size: cover;

    border-radius: 5vh;

    margin-right: 2vw;

}

.col {
    margin-top: 2vh;

    display: flex;
    flex-direction: column;
}

#icon-row-flex {

    display: flex;
    flex-direction: column;
}


#pip-credit {
    margin-right: 10vw;
}

#icon-mail {
    margin-top: 4vh;
}

.icon {
    position: relative;
    height: 4vh;
    padding: 1vh;
}