@import '../styles';

#acs-google-form {
    width: 100vw;
    height: 240vh;
    padding: 0;
}

#agenda {
    width: 50vw;
    height: 100vh;
}

.acstitle {
    color: $black;
    font-style: normal;
    font-size: 4vh;

}

.acs-sub-title {
    position: relative;
    color: $primary;
    font-style: italic;
    font-size: 4vh;
}

#info-container {
    padding-bottom: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
}

#what-is-acs-container {
    position: relative;
    // width: 50vw;
    height: 20vh;
    text-align: center;

    min-width: 300px;

    min-height: 30vh;

    @media (max-width: 1000px) {
        min-height: 70vh;

    }
}

#spacer {
    padding-bottom: -3vh;
}


#text {
    position: relative;
    font-size: 3vh;
    margin-bottom: 1.5em;
}

#speaker-container {
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
}



//speaker section styling
.speaker {
    display: flex;
    align-items: center;

    height: 25vh;
    text-align: center;

    min-width: 25vw;

    @media (max-width: 1000px) {
        min-height: 65vh;
        min-width: 90vw;
    }

    min-height: 15vh;
}

.speaker-image {
    object-fit: cover;

    width: 13vw;
    height: 18vh;
    border-radius: 17%;
    margin-right: 1vw;
    margin-left: 10vw;
    border: 2px solid $black;
    box-shadow: 0vh 1vh 1vh #c0c0c0;

}

.speaker-info {
    position: relative;
    text-align: left;
    width: 40vw;
    height: 15vh;

    display: flex;
    justify-content: center;

    min-height: 10vh;
    margin-left: 2vw;
    /* Adjust margin for spacing */
    flex: 10;
    /* Let the speaker info expand to fill available space */
}

.speaker-name {
    font-size: 1.2em;
    margin: 0;
    text-decoration: underline;
}

.speaker-description {
    font-size: 2.4vh;
    top: 2vh;
}

.speaker-image-div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    padding-top: 5vh;
}







//download button styling
$button-bg-color: #3498db;
$button-text-color: #fff;
$button-hover-bg-color: #2980b9;
$button-hover-text-color: #fff;
$button-border-radius: 5px;
$button-padding: 10px 20px;
$button-font-size: 16px;
$button-font-weight: bold;
$button-transition: 0.3s;

#download-button {
    display: inline-block;
    background-color: $button-bg-color;
    color: $button-text-color;
    padding: $button-padding;
    border-radius: $button-border-radius;
    text-decoration: none;
    font-size: $button-font-size;
    font-weight: $button-font-weight;
    transition: background-color $button-transition, color $button-transition;

    &:hover {
        background-color: $primary;
        color: $button-hover-text-color;
    }

    &:active {
        transform: scale(0.98);
    }

    width: 20vw;
    height: 10vh;
    text-align: center;
    // padding-top: 5vh;
    margin-left: 38vw;

    font-size: 3vh;


    @media (max-width: 1000px) {
        margin-top: 5vh;
        min-height: 20vh;
        min-width: 40vw;

    }
}


// google form code embed specifications:
// width="640" height="1672" frameborder="0" marginheight="0" marginwidth="0"