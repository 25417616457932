@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap);
*{font-family:"Oswald",sans-serif}html,body{margin:0;height:100%}body{background-color:#fff}
*{font-family:"Oswald",sans-serif}html,body{margin:0;height:100%}body{background-color:#fff}.navbar{display:flex;flex-direction:column;justify-content:center;align-items:center;flex-direction:row;justify-content:start;background-color:#fff;box-shadow:0vh .3vh .5vh silver;z-index:1;position:-webkit-sticky;position:sticky;top:0}.navlink-animation{display:inline-block;position:relative;color:#50a2ff;text-decoration:none;margin:.5vh;padding:1.5vh;font-size:2.5vh;border-radius:15px}.navlink-animation:after{content:"";position:absolute;width:100%;transform:scaleX(0);height:.2vh;bottom:0;left:0;background-color:#50a2ff;transform-origin:center;transition:transform .25s ease-out}.navlink-animation:hover:after{transform:scaleX(1);transform-origin:center}.nav-bar-logo{width:3rem;height:3rem;border-radius:5vh;z-index:0}
*{font-family:"Oswald",sans-serif}html,body{margin:0;height:100%}body{background-color:#fff}.YouTube-video{display:block;position:relative;top:10em;left:50em;width:40vw;height:40vh}.title{color:#50a2ff;font-style:italic;font-size:4vh}.mission{text-align:center}#mission-description{font-size:3vh}#tutorials{margin-left:5vh;margin-top:-20vh;margin-bottom:1vh}.description{width:50vw;margin-left:5vh;margin-bottom:1vh;font-size:3vh}#indiv-lessons{margin-left:5vh;margin-bottom:1vh}#centered{position:absolute;top:65%;left:52.5%;transform:translate(-50%, -50%);font-size:4vh}.header{height:60vh;display:block;background:rgba(0,0,0,0) url(/static/media/white-flowers.c79bc016.jpg);background-attachment:fixed;filter:brightness(55%)}#container{position:relative;text-align:center;color:#fff}#center-text{color:#fff;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);font-size:7vw}#partners{margin-top:10vh;text-align:center}.logo-strip{display:flex;flex-direction:row;height:20vh;width:50vw;margin-left:10vh;margin-top:4vh;margin-bottom:10vh}#scroll{width:86vw;overflow-x:auto;overflow-y:hidden;white-space:nowrap}#awesome{background-color:#000;margin-top:-2vh;height:25vh}#ekcep{height:15vh;margin-top:2vh}
*{font-family:"Oswald",sans-serif}html,body{margin:0;height:100%}body{background-color:#fff}.main-footer{color:#fff;background-color:#50a2ff;box-shadow:0px 2px 5px silver;bottom:0;width:100%;height:20vh}.row{display:flex;flex-direction:row;justify-content:center;align-items:center}.list-unstyled{list-style:none;display:flex;flex-direction:column;align-items:flex-start}#logo{background-image:url(/static/media/logo.a206e4e7.jpg);height:5em;width:5em;background-size:cover;border-radius:5vh;margin-right:2vw}.col{margin-top:2vh;display:flex;flex-direction:column}#icon-row-flex{display:flex;flex-direction:column}#pip-credit{margin-right:10vw}#icon-mail{margin-top:4vh}.icon{position:relative;height:4vh;padding:1vh}
*{font-family:"Oswald",sans-serif}html,body{margin:0;height:100%}body{background-color:#fff}#acs-google-form{width:100vw;height:240vh;padding:0}#agenda{width:50vw;height:100vh}.acstitle{color:#000;font-style:normal;font-size:4vh}.acs-sub-title{position:relative;color:#50a2ff;font-style:italic;font-size:4vh}#info-container{padding-bottom:5vh;display:flex;flex-direction:column;justify-content:center;flex-wrap:wrap}#what-is-acs-container{position:relative;height:20vh;text-align:center;min-width:300px;min-height:30vh}@media(max-width: 1000px){#what-is-acs-container{min-height:70vh}}#spacer{padding-bottom:-3vh}#text{position:relative;font-size:3vh;margin-bottom:1.5em}#speaker-container{text-align:center;display:flex;flex-direction:column;align-items:center;flex-wrap:wrap}.speaker{display:flex;align-items:center;height:25vh;text-align:center;min-width:25vw;min-height:15vh}@media(max-width: 1000px){.speaker{min-height:65vh;min-width:90vw}}.speaker-image{object-fit:cover;width:13vw;height:18vh;border-radius:17%;margin-right:1vw;margin-left:10vw;border:2px solid #000;box-shadow:0vh 1vh 1vh silver}.speaker-info{position:relative;text-align:left;width:40vw;height:15vh;display:flex;justify-content:center;min-height:10vh;margin-left:2vw;flex:10 1}.speaker-name{font-size:1.2em;margin:0;text-decoration:underline}.speaker-description{font-size:2.4vh;top:2vh}.speaker-image-div{display:flex;justify-content:center;flex-wrap:wrap;padding-top:5vh}#download-button{display:inline-block;background-color:#3498db;color:#fff;padding:10px 20px;border-radius:5px;text-decoration:none;font-size:16px;font-weight:bold;transition:background-color .3s,color .3s;width:20vw;height:10vh;text-align:center;margin-left:38vw;font-size:3vh}#download-button:hover{background-color:#50a2ff;color:#fff}#download-button:active{transform:scale(0.98)}@media(max-width: 1000px){#download-button{margin-top:5vh;min-height:20vh;min-width:40vw}}
*{font-family:"Oswald",sans-serif}html,body{margin:0;height:100%}body{background-color:#fff}html,body,#root,.App{margin:0;padding:0;height:100%;overflow-x:hidden}.App{display:flex;flex-direction:column;justify-content:flex-start}h1{color:#000;text-align:center;background-color:#fff;margin-top:0px;padding:10px}p{text-align:center;position:absolute;bottom:0}.content{z-index:0;height:100%}
