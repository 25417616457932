@import '../styles';

.navbar {
  @include flexCenter();
  flex-direction: row;
  justify-content: start;

  background-color: $white;

  box-shadow: 0vh 0.3vh 0.5vh #c0c0c0;
  z-index: 1;

  position: -webkit-sticky;
  position: sticky;
  top: 0;

}

// .navlink {

//   color: $primary;

// }

.navlink-animation {
  display: inline-block;
  position: relative;
  color: $primary;

  text-decoration: none;

  margin: 0.5vh;
  padding: 1.5vh;

  font-size: 2.5vh;

  border-radius: 15px;
}

.navlink-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 0.2vh;
  bottom: 0;
  left: 0;
  background-color: $primary;
  transform-origin: center;
  transition: transform 0.25s ease-out;
}

.navlink-animation:hover:after {
  transform: scaleX(1);
  transform-origin: center;
}


// .active {}

.nav-bar-logo {
  width: 3rem;
  height: 3rem;

  border-radius: 5vh;

  z-index: 0;

}